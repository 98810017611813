<template functional>
  <vx-card class="px-4" :class="data.staticClass">
    <!-- MAIL HEAD -->
    <div class="vx-row">
      <div v-if="schedule && schedule.id" class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
        <!-- Sender Details -->
        <div class="flex items-center">
          <div class="flex flex-col">
            <h5 class="mb-1">Examinador: {{ props.schedule.extra }}</h5>
            <h5 class="mb-1">Instrutor: {{ props.schedule.employee_id ? props.schedule.employee.name : '' }}</h5>
            <h5 class="mb-1">Veículo: {{ props.schedule.vehicle ? props.schedule.vehicle.brand + ' ' + props.schedule.vehicle.model + ' - ' + props.schedule.vehicle.license_plate : '' }}</h5>
          </div>
        </div>
        <!-- /Sender Details -->

        <!-- Mail Time & Date -->
        <div class="flex flex-col justify-end">
          <!-- <span class="text-right font-semibold">Agendamento nº: 1</span> -->
          <span class="text-right font-semibold">Data: {{ props.schedule.start_date | dateTime('date') }}</span>
          <div class="flex justify-end mt-2 font-semibold">
            <span class="text-right">Horário: {{ props.schedule.start_time.slice(0, 5) }} até {{ props.schedule.end_time.slice(0, 5) }}</span>
          </div>
          <div class="flex justify-end mt-2 font-semibold">
            <!-- <span class="text-right">Categoria: {{ props.schedule.category }}</span> -->
          </div>
        </div>
      </div>
      <!-- /Mail Time & Date -->
    </div>

    <!-- CONTENT -->
    <!-- <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mail__content break-words mt-8 mb-4"></div>
      </div>
    </div> -->

    <!-- MAIL ATTACHMENTS -->
    <!-- <div class="vx-row">
      <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid mb-6 flex">
        <feather-icon icon="PaperclipIcon" class="mr-2" />
        <span class="block py-4">ATTACHMENTS</span>
      </div>
      <div class="flex">
        <div class="mail__attachment" v-for="(attachment, index) in 2" :key="index">
          <vs-chip color="primary" class="px-4 py-2 mr-3">attachment</vs-chip>
        </div>
      </div>
    </div> -->
  </vx-card>
</template>

<script>
export default {
  props: {
    schedule: {
      type: Object,
      required: true
    }
  }
}
</script>
