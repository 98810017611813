<template>
    <div id="schedule-practical" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
      <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
        <object :data="srcPdf" width="100%" style="height: 600px" />
      </vs-popup>

      <vs-popup class="popup50" :fullscreen="false" title="Vincular Instrutor e Veículo" :active.sync="popupAddEmployeeVehicle">
        <div class="vx-row">
          <div class="vx-col md:w-full w-full mt-0">
            <label class="vs-input--label">Instrutor</label>
            <v-select class="vue_select_drop_size_150" v-model="employee_id"
              data-vv-as="Instrutor" data-vv-scope="add_employee_vehicle" v-validate.initial="'required'" name="employee_id"
              :reduce="option => option.value" :options="employees" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">Desculpe, nenhum resultado encontrado.</template>
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('add_employee_vehicle.employee_id')">{{ errors.first('add_employee_vehicle.employee_id') }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-full w-full mt-0">
            <label class="vs-input--label">Veículo</label>
            <v-select class="vue_select_drop_size_90" v-model="vehicle" :reduce="option => option.data" :options="vehicles" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <vs-divider></vs-divider>
          <div class="vx-col w-full mt-0">
            <div class="mt-2 flex flex-wrap items-center justify">
              <vs-button class="w-full" @click="addEmployeeVehicle" :disabled="validateForm">Acicionar Instrutor e Veículo</vs-button>
            </div>
          </div>
        </div>
    </vs-popup>

      <vs-sidebar class="items-no-padding" parent="#schedule-practical" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isMedicalExamSidebarActive">
        <schedule-sidebar @closeSidebar="toggleMedicalExamSidebar" />
      </vs-sidebar>

      <!-- MENU DE CONTEXTO -->
      <vue-context ref="menu">
        <li v-if="contextAdd">
          <a href="#" @click="contextMenuClicked('ADD_TIME')" class="flex items-center text-sm hover:text-primary">
            <feather-icon icon="PlusCircleIcon" svgClasses="w-5 h-5" />
            <span class="ml-2">Adicionar Horário</span>
          </a>
        </li>
        <li v-if="contextChange">
          <a href="#" @click="contextMenuClicked('CHANGE_TIME')" class="flex items-center text-sm hover:text-primary">
            <feather-icon icon="EditIcon" svgClasses="w-5 h-5" />
            <span class="ml-2">Alterar Horário</span>
          </a>
        </li>
        <li v-if="contextCopy">
          <a href="#" @click="contextMenuClicked('COPY')" class="flex items-center text-sm hover:text-primary">
            <feather-icon icon="CopyIcon" svgClasses="w-5 h-5" />
            <span class="ml-2">Copiar Grupo</span>
          </a>
        </li>
        <li v-if="contextPaste">
          <a href="#" @click="contextMenuClicked('PASTE')" class="flex items-center text-sm hover:text-primary">
            <feather-icon icon="FilePlusIcon" svgClasses="w-5 h-5" />
            <span class="ml-2">Colar Grupo</span>
          </a>
        </li>
        <li v-if="contextCopy">
          <a href="#" @click="contextMenuClicked('ADD_EMPLOYEE_VEHICLE')" class="flex items-center text-sm hover:text-primary">
            <feather-icon icon="UserPlusIcon" svgClasses="w-5 h-5" />
            <span class="ml-2">Vincular Instrutor e Veículo</span>
          </a>
        </li>
        <li v-if="contextDelete">
          <a href="#" @click="contextMenuClicked('DELETE')" class="flex items-center text-sm hover:text-danger">
            <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
            <span class="ml-2">Excluir Agendamento</span>
          </a>
        </li>
      </vue-context>

      <div :class="{'sidebar-spacer': clickNotClose}" class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0">

        <!-- SEARCH BAR AND MENU ICON -->
        <div class="flex border d-theme-dark-bg items-center">
          <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleMedicalExamSidebar(true)"/>
          <!-- <vs-input icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Search Mail" v-model="searchQuery" class="vs-input-no-border vs-input-no-shdow-focus w-full" /> -->
        </div>

        <!-- EMAIL ACTION BAR -->
        <div class="email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
          <div class="vx-row">
            <div class="vx-col md:w-2/3 w-full mt-0">
              <label class="vs-input--label">Aluno(s)</label>
              <v-select v-model="students" @search="debouncedGetSearchStudent" multiple :clearable="true" :filterable="true" style="background-color: white;"
                :options="studentOptions"
                placeholder="Digite CPF ou nome do aluno..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <vs-input v-model="examinador" class="w-full" label="Examinador" />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/5 w-full mt-0">
                <label class="vs-input--label">Categoria</label>
                <v-select v-model="category" :clearable="false" style="background-color: white;"
                  :reduce="option => option.value" :options="categoryCNHOptions"
                  placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <feather-icon @click="students = []" title="Limpar seleção de alunos" icon="MinusCircleIcon" class="ml-2 mt-6 inline-flex rounded-full" svgClasses="w-8 h-8 cursor-pointer hover:text-primary"></feather-icon>
            </div>

            <div class="vx-col md:w-3/5 w-full mt-0">
              <div class="mt-4 flex flex-wrap items-center">

                <vx-tooltip text="Período do Relatório" position="top" class="ml-auto mr-2 mt-1">
                  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer hover:text-primary">
                    <a href.prevent class="flex cursor-pointer">
                      <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="p-2 rounded-full" :class="[`text-primary`, 'mb-0']"
                        :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
                      <!-- <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="mt-1 ml-3" /> -->
                    </a>
                    <vs-dropdown-menu class="w-60" style="z-index: 200001">
                      <vs-input type="date" v-model="reportFirstPeriod" class="w-full" label="Data Inicial" />
                      <vs-input type="date" v-model="reportLastPeriod" class="w-full" label="Data Final" />
                      <span class="text-danger text-sm" v-show="periodError">O período inicial é maior que o final.</span>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </vx-tooltip>

                <v-select class="w-48 mr-2" :clearable="false" v-model="orderBy" :reduce="option => option.value" style="background-color: white;"
                  :options="[
                    {value: 'date', label: 'Ordem Data'},
                    {value: 'student', label: 'Ordem Aluno'},
                    {value: 'employee', label: 'Ordem Profissional'},
                  ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>

                <vx-tooltip text="Relatório Excel" position="top" class="mt-2 mr-2">
                  <svg @click="print('EXCEL')" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    class="svg-hover h-8 w-8 cursor-pointer" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z" /></svg>
                </vx-tooltip>
                <vx-tooltip text="Relatório PDF" position="top" class="mt-2">
                  <svg @click="doRelatorioPdf" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    class="svg-hover h-8 w-8 cursor-pointer" aria-hidden="true" focusable="false" width="0.86em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1536 1792"><path d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z" /></svg>
                </vx-tooltip>
              </div>
            </div>
          </div>
        </div>

          <!-- SCHEDULE -->
          <component :is="scrollbarTag" class="email-content-scroll-area" :settings="settings" ref="mailListPS" :key="$vs.rtl">
            <div class="vs-con-loading__container" id="loading">
              <vs-table v-for="(table, tIndex) in tables" :key="tIndex" :data="selectedDays" class="bordered" hoverFlat style="overflow: -webkit-paged-y">
                <template slot="thead">
                  <vs-th style="font-size: 11px; min-width: 124px" v-for="(hr, i) in handleRange(table)" :key="i">
                      {{ weekDays[selectedDays[hr - 1].weekdayPosition] }} {{ selectedDays[hr - 1].id | dateTime('date') }}
                  </vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr height="30" style="font-size: 12px; opacity: 1 !important; vertical-align: top;">
                    <vs-td v-for="(hr, itd) in handleRange(table)" :key="itd" @contextmenu.prevent.native.stop="contextMenu($event, hr - 1)">
                      <feather-icon v-for="(holiday, hd) in selectedDays[hr - 1].holidaySchedule" :key="`${hd}_holiday`" :title="holiday.holidayName" class="mr-1" icon="StarIcon" svgClasses="w-4 h-4" />
                      <span :key="hour" v-for="(sc, hour) in _.groupBy(selectedDays[hr-1].studentSchedule, 'start_time')" @contextmenu.prevent.stop="contextMenu($event, hr - 1, hour)">
                        <span class="mb-1 font-semibold flex p-1 bg-secondary text-white cursor-pointer" @click="addSchedule(selectedDays[hr - 1], hour)">{{ hour }}</span>
                        <span class="cursor-pointer hover:text-primary hover:semibold" :key="idx" v-for="(schedule, idx) in sc.sort((a, b) => { if (a.student && b.student) return a.student.name.localeCompare(b.student.name)})" @click.stop="viewSchedules(sc)">
                          <div v-if="schedule.student">
                            <span class="flex" @contextmenu.prevent.stop="contextMenu($event, -1)">{{ (schedule.student && schedule.student.name) ? schedule.student.name : '' }}</span> <hr>
                          </div>
                        </span>
                        <vs-divider></vs-divider>
                      </span>
                    </vs-td>
                  </vs-tr>

                  <vs-tr style="opacity: 1 !important; border: 10px">
                    <vs-td :colspan="handleRange(table).length + 1" style="text-align:right">
                      <vs-divider></vs-divider>
                    </vs-td>
                  </vs-tr>

                </template>
              </vs-table>
            </div>
            <div class="mt-6 flex flex-wrap items-center justify-center">
              <vs-alert class="w-5/6" style="height: -webkit-fill-available;" color="warning" title="Defina o período" :active="!tables">
                  Selecione a(s) data(s) no calendário ou defina um intervalo de datas para ver
                  os agendamentos conforme o filtro superior.
              </vs-alert>
            </div>
          </component>
        </div>

        <!-- SCHEDULE VIEW SIDEBAR -->
        <schedule-view :isSidebarActive="isSidebarActive" @closeSidebar="closeViewSidebar"></schedule-view>
    </div>
</template>

<script>

import ScheduleSidebar               from './PracticalExamSidebar.vue'
import ScheduleView                  from './PracticalExamView.vue'
import _                             from 'lodash'
import { VueContext }                from 'vue-context'
import moment                        from 'moment'
import VuePerfectScrollbar           from 'vue-perfect-scrollbar'
import moduleSchedulePracticalExam   from '@/store/schedule-practical-exam/moduleSchedulePracticalExam.js'
import moduleEmployee                from '@/store/employee/moduleEmployee.js'
import moduleVehicle                 from '@/store/vehicle/moduleVehicle.js'
import moduleScheduleTime            from '@/store/schedule-time/moduleScheduleTime.js'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

export default {
  data () {
    return {
      addScheduleParam: {},
      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,
      orderBy: 'student',

      contextMenuIdx       : null,
      contextMenuHour      : null,
      contextAdd           : false,
      contextChange        : false,
      contextDelete        : false,
      contextCopy          : false,
      contextPaste         : false,

      selectedDay          : null,
      selectedHour         : null,

      schedules            : [],
      scheduleGroup        : [],
      isSidebarActive      : false,
      showThread           : false,
      clickNotClose        : true,
      isMedicalExamSidebarActive : true,
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30
      },
      weekDays: [0, 'SEG.', 'TER.', 'QUA.', 'QUI.', 'SEX.', 'SÁB.', 'DOM.'],
      students: [],
      studentOptions: [],
      examinador: null,
      type: 'practical_exam',
      status: 'Aguardando Teste',     // Apto Inapto
      type_service: null,
      reportFirstPeriod: '',
      reportLastPeriod: '',
      periodError: false,

      category: null,
      categoryCNHOptions: [
        { label: 'A',   value: 'A'   },
        { label: 'B',   value: 'B'   },
        { label: 'C',   value: 'C'   },
        { label: 'D',   value: 'D'   },
        { label: 'E',   value: 'E'   },
        { label: 'AB',  value: 'AB'  },
        { label: 'ACC', value: 'ACC' }
      ],
      popupAddEmployeeVehicle: false,
      employee_id: null,
      vehicle: null
    }
  },
  watch: {
    selectedDays (value) {
      if (value.length) {
        this.openLoading()
        this.debouncedGetFilterSchedule()
      }
      // AO TROCAR O PERÍODO DE DATAS DO CALENDÁRIO ZERO O PERÍODO MANUAL DO RELATÓRIO
      this.reportFirstPeriod = ''
      this.reportLastPeriod = ''
    },
    isSidebarActive (value) {
      if (!value) this.showThread = false
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    _ () {
      return _
    },
    user () {
      return this.$store.state.AppActiveUser
    },
    validateForm () {
      return this.errors.any()
    },
    scheduleTime () {
      return this.$store.state.scheduleTime.scheduleTime
    },
    selectedDays () {
      return this.$store.state.schedulePracticalExam ? this.$store.state.schedulePracticalExam.selectedDays : null
    },
    time () {
      const time = new Date(this.$store.state.schedulePracticalExam.time)
      return `${time.getHours() < 10 ? 0 : ''}${time.getHours()}:${time.getMinutes() < 10 ? 0 : ''}${time.getMinutes()}:00`
    },
    endTime () {
      const endTime = new Date(this.$store.state.schedulePracticalExam.endTime)
      return `${endTime.getHours() < 10 ? 0 : ''}${endTime.getHours()}:${endTime.getMinutes() < 10 ? 0 : ''}${endTime.getMinutes()}:00`
    },
    tables () {
      return Math.ceil(this.selectedDays.length / 6) // CONTROLE DE COLUNAS POR TABELA DE DATAS (7 COLUNAS POR TABELA)
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    vehicles () {
      return this.$store.getters['vehicle/forSelect']
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    doRelatorioPdf () {
      const self = this
      this.$vs.dialog({
        type: 'confirm',
        color:'success',
        title:'Formato',
        acceptText: 'Retrato',
        cancelText: 'Paisagem',
        text:'Selecione a orientação da página',
        accept: self.printPortrait,
        cancel: self.printLandscait
      })
    },
    printPortrait () {
      this.printFormat = 'portrait'
      this.print('PDF')
    },
    printLandscait () {
      this.printFormat = 'landscape'
      this.print('PDF')
    },
    allowStudent () {
      const validator = this.studentValidators()
      let allow = true

      for (let i = 0; i < validator.length  && allow; i++) {
        allow = validator[i]()
      }

      return allow
    },
    studentValidators () {
      const me = this
      // nao existe configuracao de validacao cadastrada
      if (!me.$user.company.config_schedule) {
        return []
      }
      // inserir aqui os validadores
      const valid  = [
        () => {
          let resp = true
          if (me.$user.company.config_schedule.block_debit) {
            const student = me.students.find(e => e.data.defaulting)
            if (student) {
              me.$vs.notify({
                time: 6000,
                title: 'AVISO',
                text: `Aluno ${student.data.name} com pendência no financeiro. Agendamento impedido`,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                position: 'top-center'
              })
              resp = false
            }
          }
          return resp

        },
        () => {
          let resp = true
          if (me.$user.company.config_schedule.notify_debit) {
            const student = me.students.find(e => e.data.defaulting)
            if (student) {
              me.$vs.dialog({
                type:'confirm',
                color: 'danger',
                title: 'Financeiro - Pendência',
                text: `Aluno ${student.data.name} com pendência no financeiro. Deseja continuar com o agendamento?`,
                accept:me.newAddSchedule,
                acceptText: 'Sim',
                cancelText: 'Cancelar'
              })
              resp = false
            }
          }
          return resp
        }
      ]

      return valid
    },
    openLoading () {
      this.$vs.loading({
        container: '#loading',
        scale: 0.8
      })
    },
    closeLoading () {
      this.$vs.loading.close('#loading > .con-vs-loading')
    },
    contextMenu (event, idx, hour = null) {
      if (idx === -1) return
      this.contextAdd = true
      this.contextPaste = !!this.scheduleGroup.length // sem grupo copiado não mostro colar
      this.contextCopy = false
      this.contextChange = false
      this.contextDelete = false
      if (hour) {
        this.contextChange = true
        this.contextDelete = true
        this.contextCopy = true
      }
      this.contextMenuIdx = idx
      this.contextMenuHour = hour
      this.$refs.menu.open(event)
    },
    contextMenuClicked (action) {
      if (action === 'ADD_TIME') {
        this.addTime()
      } else if (action === 'CHANGE_TIME') {
        this.changeTime()
      } else if (action === 'DELETE') {
        this.confirmDelete()
      } else if (action === 'COPY') {
        this.copyGroup()
      } else if (action === 'PASTE') {
        this.pasteGroup()
      } else if (action === 'ADD_EMPLOYEE_VEHICLE') {
        this.handleAddEmployeeVehicle()
      }
    },
    async addTime () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_practical_exam')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      // SE JÁ EXISTIR O HORÁRIO, NAO CADASTRAR
      if (this.selectedDays[this.contextMenuIdx].studentSchedule.find(a => a.start_time === this.time)) return

      try {
        // SALVA NO BANCO
        const payload = {
          weekdays: this.scheduleTime.weekdays || [], // ARRAY 0 dom ... 6 sáb
          specific_days: [`${this.selectedDays[this.contextMenuIdx].id} ${this.time} ${this.endTime}`],
          schedule_type: this.type
        }
        await this.$store.dispatch('scheduleTime/store', payload)

        // ALTERA O STATE NO VUEX
        const payload2 = {
          contextMenuIdx: this.contextMenuIdx,  // UTILIZADO SOMENTE NO COMMIT
          start_date: this.selectedDays[this.contextMenuIdx].id,
          time: this.time,                      // UTILIZADO SOMENTE NO COMMIT
          endTime: this.endTime                 // UTILIZADO SOMENTE NO COMMIT
        }
        this.$store.commit('schedulePracticalExam/ADD_SCHEDULE_TIME', payload2)
      } catch (error) { console.error(error) }

      // ZERA AS VARIÁVEIS SOMENTE POR SEGURANÇA
      this.contextMenuIdx = null
      this.contextMenuHour = null
    },
    async changeTime () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_practical_exam')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      try {
        const payload = {
          start_time: this.time,
          end_time: this.endTime,
          duration: '00:50' // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        }

        const scheduleGroup = JSON.parse(JSON.stringify(this.selectedDays[this.contextMenuIdx].studentSchedule.filter(a => a.start_time === this.contextMenuHour)))
        const oldscheduleGroup = JSON.parse(JSON.stringify(scheduleGroup[0]))
        this.$store.commit('schedulePracticalExam/UPDATE_MASSIVE_SCHEDULES', { schedules: scheduleGroup, changes: payload })
        scheduleGroup.map(a => { // PRECISO ALTERAR 2 VEZES PQ O COMMIT DEMORA
          a.start_time = this.time
          a.end_time = this.endTime
          a.duration = '00:50'
        })
        if (scheduleGroup[0].id) {
          this.$vs.loading()
          await this.$store.dispatch('schedulePracticalExam/updateMany', scheduleGroup.filter(s => s.id)) // somente com id
          this.$vs.loading.close()
        }
        await this.$store.dispatch('scheduleTime/update', {
          schedule_type: this.type,

          date: this.selectedDays[this.contextMenuIdx].id,
          start_time: scheduleGroup[0].start_time,
          end_time: scheduleGroup[0].end_time,

          oldDate: oldscheduleGroup.start_date,
          oldStart_time: oldscheduleGroup.start_time,
          oldEnd_time: oldscheduleGroup.end_time
        })

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },
    viewSchedules (schedules) {
      //FILTRO SOMENTE AGENDAS COM ID POIS PARA ADICIONAR HORÁRIOS CRIO AGENDAS VIRTUAIS QUE CAUSAM PROBLEMAS SE ENVIADAS
      this.$store.commit('schedulePracticalExam/SET_SCHEDULES', schedules.filter(s => s.id))
      this.scheduleDetails()
    },
    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc (blob, filename) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${filename || 'Relatório'}.xlsx`
      link.click()
    },
    async print (type) {
      const selectedDays = this.selectedDays.map(day => day.id)
      const reportFirstPeriod = this.reportFirstPeriod || selectedDays[0]
      const reportLastPeriod = this.reportLastPeriod || selectedDays[selectedDays.length - 1]

      if (reportFirstPeriod && reportLastPeriod && moment(reportFirstPeriod).year() > 1000 && moment(reportLastPeriod).year() > 1000) {
        const difDays = moment(reportLastPeriod).diff(moment(reportFirstPeriod), 'days')
        if (difDays < 0) {
          this.periodError = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período inicial é maior que o final.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        } else if (difDays > ((365 * 5) + 1)) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período não pode ser maior que 5 anos.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        this.periodError = false
        this.$vs.loading()
        this.showPdfTitle = 'Relatório de Agendamentos de Exames Práticos'
        const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/schedule/examSchedule/report`, {
          firstPeriod: reportFirstPeriod,
          lastPeriod: reportLastPeriod,
          orderBy: this.orderBy,
          format: type,
          page:this.printFormat,
          category: this.category,
          payload: {
            students: this.student_id ? [this.student_id] : [],
            doctors: this.doctor_id ? [this.doctor_id] : [],
            cacs: this.cac_id ? [this.cac_id] : [],
            status: null,
            type: 'practical_exam'
          }
        }, { responseType: type === 'EXCEL' ? 'arraybuffer' : null })

        if (type === 'PDF') {
          this.srcPdf = `data:application/pdf;base64,${resp.data}`
          this.popupShowPdf = true
        } else if (type === 'EXCEL') {
          const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          this.makeBlobDoc(blob, this.showPdfTitle)
        }
        this.$nextTick(() => { this.$vs.loading.close() })

      } else {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Defina um período.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    copyGroup () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_practical_exam')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.scheduleGroup = this.selectedDays[this.contextMenuIdx].studentSchedule.filter(a => a.start_time === this.contextMenuHour).filter(a => a.id)
      this.$vs.notify({
        time: 5000,
        title: 'SUCESSO',
        text: 'Dados copiados para a memória.',
        color: 'success',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    },
    async pasteGroup () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_practical_exam')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      try {
        const selectedDay = this.selectedDays[this.contextMenuIdx]
        const hour = this.scheduleGroup[0].start_time
        const students = this.scheduleGroup.reduce((a, b) => {
          a.push(b.student)
          return a
        }, [])

        // REMOVE O ALUNO DA MARCAÇÃO CASO JÁ ESTEJA NO GRUPO AGENDADO (VERIFICAR DIA E HORÁRIO)
        selectedDay.studentSchedule.map(schedule => {
          if (schedule.student) { // PODE SER QUE EXISTA O HORÁRIO MAS SEM STUDENT
            const idx = students.findIndex(student =>  student.id === schedule.student.id && schedule.start_time === hour)
            if (idx >= 0) students.splice(idx, 1)
          }
        })

        // REMOVIDO PELA NOVA LEGISLAÇÃO
        // this.verifyWarnings(students.map(a => a.id), this.scheduleGroup[0])

        const payload = {
          students,
          employee_id: this.scheduleGroup[0].employee_id,
          start_date: selectedDay.id,
          start_time: hour, // NAO TENHO UM HORÁRIO DEGRUPO,PEGO O HORÁRIOAGRUPADO POIS TODAS AS AG DO GRUPO TEM OS MESMOS HORÁRIOS
          end_time: this.scheduleGroup[0].end_time // NAO TENHO UM HORÁRIO DE GRUPO,PEGO O HORÁRIO DA PRIMEIRA POIS TODAS AS AG DO GRUPO TEM OS MESMOS HORÁRIOS
        }
        if (students.length) {
          this.$vs.loading()
          await this.$store.dispatch('schedulePracticalExam/store', payload)
          this.$vs.loading.close()
        }

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    handleAddEmployeeVehicle () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_practical_exam')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.employee_id = null
      this.vehicle = null
      this.popupAddEmployeeVehicle = true
    },
    async addEmployeeVehicle () {
      this.popupAddEmployeeVehicle = false

      try {
        const scheduleGroup = JSON.parse(JSON.stringify(this.selectedDays[this.contextMenuIdx].studentSchedule.filter(a => a.start_time === this.contextMenuHour)))
        const scheduleGroupIds = scheduleGroup.map(a => a.id)

        const payload = {
          schedules: scheduleGroupIds,
          employee_id: this.employee_id,
          vehicle_id: this.vehicle ? this.vehicle.id : null,
          duration: '00:50' // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        }

        this.$store.commit('schedulePracticalExam/UPDATE_MASSIVE_SCHEDULES', { schedules: scheduleGroup, changes: payload })
        scheduleGroup.map(a => { // PRECISO ALTERAR 2 VEZES PQ O COMMIT DEMORA
          a.employee_id = this.employee_id
          a.vehicle_id = this.vehicle ? this.vehicle.id : null
          a.duration = '00:50'
        })
        if (scheduleGroup[0].id) {
          this.$vs.loading()
          await this.$store.dispatch('schedulePracticalExam/updateMany', scheduleGroup)
          this.$vs.loading.close()
        }
      } catch (error) { console.error(error) }
    },
    confirmDelete () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_practical_exam')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir este grupo?',
        accept: this.deleteSchedule,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deleteSchedule () {
      try {
        this.$vs.loading()
        const scheduleGroup = this.selectedDays[this.contextMenuIdx].studentSchedule.filter(a => a.start_time === this.contextMenuHour)
        const scheduleGroupIds = scheduleGroup.map(a => a.id)
        if (scheduleGroupIds[0]) {
          // TEM AGENDAMENTOS
          await this.$store.dispatch('schedulePracticalExam/delete', scheduleGroupIds)
        }
        await this.$store.dispatch('scheduleTime/delete', {
          selectedDays: [],
          schedule_type: this.type,
          date: this.selectedDays[this.contextMenuIdx].id,
          start_time: scheduleGroup[0].start_time,
          end_time: scheduleGroup[0].end_time
        })

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.error(error)
        this.$vs.loading.close()
      }
    },

    async addSchedule (selectedDay, hour) {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_practical_exam')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      let messageStudent = null, messageCategory = null
      if (!this.students.length) messageStudent = 'Selecione ao menos um aluno.'
      if (!this.category) messageCategory = 'O campo Categoria é obrigatório.'
      if (messageStudent || messageCategory) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: `${messageStudent || ''} ${messageStudent ? '<br>' : ''} ${messageCategory || ''}`,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      // usado para remover alunos já inseridos no agndamento sem apagar a seleção do select
      const students = JSON.parse(JSON.stringify(this.students))

      // AGRUPO POS STUDENT_ID PRA VER QTOS AGENDAMENTOS TEM UM MESMO ALUNO NO GRUPO
      const result = _(selectedDay.studentSchedule.filter(schedule => schedule.start_time === hour && schedule.student_id)).groupBy('student_id').map((schedules, student_id) => ({
        student_id,
        schedules
      })).value()

      // SE DETERMINADO ALUNO TIVER 2 AGENDAMENTOS NÃO EFETUO O 3º AGENDAMENTO REMOVENDO ESTE ALUNO DA LISTA ENVIADA AO DERVIDOR
      // TAMBÉM NÃO PERMITO 2 AGENDAMENTOS COM A MESMA CATEGORIA
      result.map(r => {
        if (r.schedules.length >= 2 || (r.schedules.length < 2 && r.schedules.filter(s => s.category === this.category).length)) {
          const idx = students.findIndex(o =>  parseInt(o.value) === parseInt(r.student_id))
          if (idx >= 0) students.splice(idx, 1)
        }
      })

      // pego o grupo exato do dia (podem haver mais de um grupo no mesmo dia)
      const scheduleGroup = selectedDay.studentSchedule.filter(a => a.start_time === hour)

      // REMOVIDO PELA NOVA LEGISLAÇÃO
      // this.verifyWarnings(this.students.map(a => a.value), scheduleGroup[0])

      this.addScheduleParam = {selectedDay, hour}
      if (!this.allowStudent()) return

      try {
        this.$vs.loading()
        const payload = {
          scheduleTime: this.scheduleTime,
          students: students.map(a => a.data),
          employee_id: this.employee_id,
          extra: this.examinador,
          category: this.category,
          start_date: selectedDay.id,
          start_time: hour, // NAO TENHO UM HORÁRIO DEGRUPO,PEGO O HORÁRIOAGRUPADO POIS TODAS AS AG DO GRUPO TEM OS MESMOS HORÁRIOS
          end_time: scheduleGroup[0].end_time // NAO TENHO UM HORÁRIO DE GRUPO,PEGO O HORÁRIO DA PRIMEIRA POIS TODAS AS AG DO GRUPO TEM OS MESMOS HORÁRIOS
        }
        await this.$store.dispatch('schedulePracticalExam/store', payload)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    // students deve ser um array de ids
    // REMOVIDO PELA NOVA LEGISLAÇÃO
    // async verifyWarnings (students, schedule) {
    //   schedule.type = this.type
    //   const response = await this.$http.post(`${process.env.VUE_APP_API_URL}/schedule/lastByStatus`, { schedule, students, status: 'Inapto', pastDays: 15})
    //   if (response.data.length) {
    //     const students = response.data.map(a => a.student.name)
    //     this.$vs.notify({
    //       time: 10000,
    //       title: 'AVISO',
    //       text: `O(s) aluno(s) ${students.join(', ')}, foram agendados como INAPTOS há menos de 15 dias.`,
    //       iconPack: 'feather',
    //       icon: 'icon-alert-circle',
    //       color: 'warning'
    //     })
    //   }
    // },
    handleRange (row, colsPerRow = 6) {
      if (row === this.tables && this.selectedDays.length % colsPerRow > 0) {
        return _.range((row * colsPerRow) - (colsPerRow - 1), (row * colsPerRow) - (colsPerRow - 1) + (this.selectedDays.length % colsPerRow))
      }
      return _.range((row * colsPerRow) - (colsPerRow - 1), (row * colsPerRow) + 1)
    },
    async filterSchedule () {
      try {
        this.openLoading()
        const payload = {
          getByDate: true,
          getByPeriod: false,
          type: this.type,
          dates: this.selectedDays.map(day => day.id),
          student_id: this.student_id,
          employee_id: this.employee_id
        }
        await this.$store.dispatch('schedulePracticalExam/fetchFilter', payload) // BUSCA OS AGENDAMENTOS NA API CONFORME FILTRO
        this.$store.commit('schedulePracticalExam/FILL_SELECTED_DAYS', this.scheduleTime)           // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
        this.closeLoading()
      } catch (error) { console.error(error) }
    },

    scheduleDetails () {
      this.isSidebarActive = true
    },
    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: 'STUDENT', with: ['schedules'] })
          .then(function (response) {
            for (const k in response.data) {
              //INCLUIR SOMENTE OS ALUNOS QUE NÃO EXISTEM
              const idx = this.studentOptions.findIndex((e) => e.value === response.data[k].id)
              if (idx === -1) {
                this.studentOptions.push({
                  value: response.data[k].id,
                  label:  `${response.data[k].name} ${response.data[k].cpf || ''}`,
                  data:   response.data[k]
                })
              }
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })

      } else {
        loading(false)
      }
    },
    closeViewSidebar () {
      this.isSidebarActive = false
    },
    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.isMedicalExamSidebarActive = this.clickNotClose = false
      } else {
        this.isMedicalExamSidebarActive = this.clickNotClose = true
      }
    },
    toggleMedicalExamSidebar (value = false) {
      if (!value) {
        this.closeViewSidebar()
        if (this.clickNotClose) {
          return
        }
      }
      this.isMedicalExamSidebarActive = value
    },
    async newAddSchedule () {
      const selectedDay = this.addScheduleParam.selectedDay
      const hour = this.addScheduleParam.hour

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_practical_exam')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      let messageStudent = null, messageCategory = null
      if (!this.students.length) messageStudent = 'Selecione ao menos um aluno.'
      if (!this.category) messageCategory = 'O campo Categoria é obrigatório.'
      if (messageStudent || messageCategory) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: `${messageStudent || ''} ${messageStudent ? '<br>' : ''} ${messageCategory || ''}`,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      // usado para remover alunos já inseridos no agndamento sem apagar a seleção do select
      const students = JSON.parse(JSON.stringify(this.students))

      // AGRUPO POS STUDENT_ID PRA VER QTOS AGENDAMENTOS TEM UM MESMO ALUNO NO GRUPO
      const result = _(selectedDay.studentSchedule.filter(schedule => schedule.start_time === hour && schedule.student_id)).groupBy('student_id').map((schedules, student_id) => ({
        student_id,
        schedules
      })).value()

      // SE DETERMINADO ALUNO TIVER 2 AGENDAMENTOS NÃO EFETUO O 3º AGENDAMENTO REMOVENDO ESTE ALUNO DA LISTA ENVIADA AO DERVIDOR
      // TAMBÉM NÃO PERMITO 2 AGENDAMENTOS COM A MESMA CATEGORIA
      result.map(r => {
        if (r.schedules.length >= 2 || (r.schedules.length < 2 && r.schedules.filter(s => s.category === this.category).length)) {
          const idx = students.findIndex(o =>  parseInt(o.value) === parseInt(r.student_id))
          if (idx >= 0) students.splice(idx, 1)
        }
      })

      // pego o grupo exato do dia (podem haver mais de um grupo no mesmo dia)
      const scheduleGroup = selectedDay.studentSchedule.filter(a => a.start_time === hour)

      // REMOVIDO PELA NOVA LEGISLAÇÃO
      // this.verifyWarnings(this.students.map(a => a.value), scheduleGroup[0])

      try {
        this.$vs.loading()
        const payload = {
          scheduleTime: this.scheduleTime,
          students: students.map(a => a.data),
          employee_id: this.employee_id,
          extra: this.examinador,
          category: this.category,
          start_date: selectedDay.id,
          start_time: hour, // NAO TENHO UM HORÁRIO DEGRUPO,PEGO O HORÁRIOAGRUPADO POIS TODAS AS AG DO GRUPO TEM OS MESMOS HORÁRIOS
          end_time: scheduleGroup[0].end_time // NAO TENHO UM HORÁRIO DE GRUPO,PEGO O HORÁRIO DA PRIMEIRA POIS TODAS AS AG DO GRUPO TEM OS MESMOS HORÁRIOS
        }
        await this.$store.dispatch('schedulePracticalExam/store', payload)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  components: {
    ScheduleSidebar,
    ScheduleView,
    VuePerfectScrollbar,
    VueContext,
    vSelect
  },
  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
    this.debouncedGetFilterSchedule = _.debounce(this.filterSchedule, 1000)

    if (!moduleSchedulePracticalExam.isRegistered) {
      this.$store.registerModule('schedulePracticalExam', moduleSchedulePracticalExam)
      moduleSchedulePracticalExam.isRegistered = true
    }

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleVehicle.isRegistered) {
      this.$store.registerModule('vehicle', moduleVehicle)
      moduleVehicle.isRegistered = true
    }
    this.$store.dispatch('vehicle/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleScheduleTime.isRegistered) {
      this.$store.registerModule('scheduleTime', moduleScheduleTime)
      moduleScheduleTime.isRegistered = true
    }
    this.$store.dispatch('scheduleTime/fetch', this.type).then(() => { }).catch(err => { console.error(err) })

    this.setSidebarWidth()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";

.popup50 .vs-popup {
  width: 50% !important;
}

#schedule-practical th .vs-table-text {
    cursor: default;
}
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#schedule-practical td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#schedule-practical td:hover {
  border-bottom: 1px solid #353434;
}
#schedule-practical td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#schedule-practical .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#schedule-practical .vs-table--thead {
  border: 1px solid transparent;
}
.icon {
  font-size: 16px;
}
#schedule-practical .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#schedule-practical .ps__scrollbar-y-rail {
  z-index: 99999;
}
#schedule-practical .vs-con-table .vs-con-tbody {
    width: 100%;
    overflow: initial;
}
.vue_select_drop_size_90 .vs__dropdown-menu {
  max-height: 90px;
}
.vue_select_drop_size_150 .vs__dropdown-menu {
  max-height: 150px;
}

</style>
