<template>
  <div>
    <vs-sidebar click-not-close parent="#schedule-practical" :hidden-background="true" class="full-vs-sidebar email-view-sidebar items-no-padding" v-model="isSidebarActive" position-right>
        <div class="mail-sidebar-content px-0 sm:pb-6 h-full">
            <div class="flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg">
                <div class="flex mb-4">
                    <div class="flex items-center">
                        <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="$emit('closeSidebar')" class="cursor-pointer hover:text-primary mr-4" svg-classes="w-6 h-6"></feather-icon>
                        <h4>DETALHAMENTO DE AGENDA</h4>
                    </div>
                </div>
                <div class="ml-10 mb-4 mt-1">
                    <div class="email__actions--single flex items-baseline">
                        <!-- <vx-tooltip text="Imprimir" position="top" class="ml-4">
                          <feather-icon icon="PrinterIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="$emit('markUnread')"></feather-icon>
                        </vx-tooltip> -->
                    </div>
                </div>
            </div>

            <component :is="scrollbarTag" class="scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6" :settings="settings" :key="$vs.rtl">

              <div v-if="isSidebarActive">
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <schedule-card :schedule="scheduleSelected" />
                  </div>
                </div>

                <div class="vx-row" style="margin-top: 2.2rem">
                    <div class="vx-col w-full">
                        <vx-card>
                          <div class="vx-row">
                            <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex flex items-center">
                              <h5 class="mb-1">Grupo</h5>
                              <!-- <span class="text-lg">Click here to <span class="text-primary font-semibold cursor-pointer">Reply</span> or <span class="text-primary font-semibold cursor-pointer">Forward</span></span> -->
                                  <vx-tooltip text="Atualizar Notas dos Selecionados" position="top" class="ml-auto">
                                    <feather-icon icon="CastIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="updateSchedules"></feather-icon>
                                  </vx-tooltip>
                              <!-- MOVE TO DROPDOWN -->
                              <vx-tooltip text="Alterar Status" position="top" class="ml-4">
                                <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                                  <feather-icon icon="FolderIcon" svg-classes="h-6 w-6" class="mb-2"></feather-icon>

                                  <vs-dropdown-menu class="w-60">
                                    <vs-dropdown-item @click="updateStatus('Apto')">
                                      <span class="flex items-center">
                                        <div class="h-4 w-4 inline-block rounded-full mr-3 bg-success"></div>
                                        <span>Apto</span>
                                      </span>
                                    </vs-dropdown-item>

                                    <vs-dropdown-item @click="updateStatus('Inapto')">
                                      <span class="flex items-center">
                                        <div class="h-4 w-4 inline-block rounded-full mr-3 bg-danger"></div>
                                        <span>Inapto</span>
                                      </span>
                                    </vs-dropdown-item>

                                    <vs-dropdown-item @click="updateStatus('Aguardando Teste')">
                                      <span class="flex items-center">
                                        <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
                                        <span>Aguardando Teste</span>
                                      </span>
                                    </vs-dropdown-item>
                                  </vs-dropdown-menu>
                                </vs-dropdown>
                              </vx-tooltip>

                              <vx-tooltip text="Deletar Selecionados" position="top" class="ml-4">
                                <feather-icon icon="TrashIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="confirmDelete"></feather-icon>
                              </vx-tooltip>
                            </div>
                          </div>
                          <vs-table noDataText="Nenhum Agendamento." :data="schedules" class="bordered" hoverFlat style="overflow: -webkit-paged-y">
                              <template slot="thead">
                                <vs-th style="font-size: 11px; width: 40px">
                                  <vs-checkbox v-model="selectAllCheckBox" :disabled="!schedules.length" icon-pack="feather" :icon="selectAllIcon" class="select-all-chexkbox">Todos</vs-checkbox>
                                </vs-th>
                                <vs-th style="font-size: 11px;"><span>ALUNO</span></vs-th>
                                <vs-th style="font-size: 11px;"><span>CPF</span></vs-th>
                                <vs-th style="font-size: 11px;"><span>CATEG.</span></vs-th>
                                <vs-th style="font-size: 11px;"><span>STATUS</span></vs-th>
                              </template>
                              <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(schedule, indextr) in data" style="font-size: 12px; opacity: 1 !important">
                                  <vs-td><vs-checkbox v-model="selectedSchedules" :vs-value="schedule" class="vs-checkbox-small ml-0 mr-1"></vs-checkbox></vs-td>
                                  <vs-td><span class="cursor-default font-semibold">{{ schedule.student.name }}</span></vs-td>
                                  <vs-td><span class="cursor-default font-semibold">{{ schedule.student.cpf }}</span></vs-td>
                                  <vs-td><span class="cursor-default font-semibold">{{ schedule.category }}</span></vs-td>
                                  <vs-td>
                                    <span class="cursor-default font-semibold flex items-center" :class="`text-${schedule.status === 'Apto' ? 'success' : schedule.status === 'Inapto' ? 'danger' : schedule.status === 'Apto Com Restrições' ? 'warning' : schedule.status === 'ABSENCE' ? 'secondary' : ''}`">
                                      <feather-icon class="mr-1" v-if="schedule.status === 'ABSENCE'" icon="SlashIcon" svgClasses="w-4 h-4" />
                                      <span>{{ schedule.status | scheduleStatus }}</span>
                                    </span>
                                  </vs-td>
                                </vs-tr>
                              </template>
                            </vs-table>
                        </vx-card>
                    </div>
                </div>
              </div>
            </component>
        </div>
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar      from 'vue-perfect-scrollbar'
import moduleStudentManagement  from '@/store/student-management/moduleStudentManagement.js'
import ScheduleCard             from './PracticalExamCard.vue'
import axios from '@/axios'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showThread: false,
      selectedSchedules: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.50
      }
    }
  },
  watch: {
    isSidebarActive (value) {
      this.selectedSchedules = []
      if (!value) {
        this.$emit('closeSidebar')
        setTimeout(() => {
          this.showThread = false
        }, 500)
      }
    }
  },
  computed: {
    scheduleSelected () {
      if (this.schedules.length) return this.schedules[0]
      return {}
    },
    user () {
      return this.$store.state.AppActiveUser
    },
    schedules () {
      return this.$store.state.schedulePracticalExam.schedules || []
    },
    selectAllCheckBox: {
      get () {
        return this.selectedSchedules.length
      },
      set (value) {
        if (value) {
          this.selectedSchedules = this.schedules
        } else {
          this.selectedSchedules = []
        }
      }
    },
    selectAllIcon () {
      return this.selectedSchedules.length === this.schedules.length ? 'icon-check' : 'icon-minus'
    },
    student () {
      return this.$store.getters['studentManagement/getStudent']
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    updateSchedules () {
      this.$vs.loading()
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/practicalExam/sync`, {
        students: this.selectedSchedules.map(e => e.student)
      })
        .then((e) => {
          this.$store.commit('schedulePracticalExam/SET_SCHEDULES', e.data)
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    async updateStatus (status) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_practical_exam')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()
        this.$store.commit('schedulePracticalExam/UPDATE_MASSIVE_SCHEDULES', { schedules: this.selectedSchedules, changes: { status, duration: '00:50'} })  // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        const selectedSchedules = JSON.parse(JSON.stringify(this.selectedSchedules))
        selectedSchedules.map(a => {
          a.status = status
          a.duration = '00:50'
        })
        await this.$store.dispatch('schedulePracticalExam/updateMany', selectedSchedules)
        this.selectedSchedules = []
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },
    async deleteSelected () {
      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()
        await this.$store.dispatch('schedulePracticalExam/delete', this.selectedSchedules.map(a => a.id))
        this.selectedSchedules = []
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },
    confirmDelete () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_practical_exam')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Confirmar Exclusão',
          text: 'Tem certeza que deseja excluir as agendas selecionadas?',
          accept: this.deleteSelected,
          acceptText: 'Excluir',
          cancelText: 'Cancelar'
        })
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    ScheduleCard
  },
  // updated () {
  //   if (!this.currentMail) return
  //   if (this.currentMail.unread && this.isSidebarActive) this.$store.dispatch('email/setUnread', { emailIds: [this.openMailId], unread: false })
  // },

  created () {
    // Register Module StudentManagement Module
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
  }
}

</script>

