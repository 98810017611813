<template>
    <div class="email__email-sidebar h-full">
      <component :is="scrollbarTag" class="email-filter-scroll-area" :settings="settings" :key="$vs.rtl">
        <div class="mt-2"><label class="vs-input--label">Tipo de calendário</label></div>
        <v-select class="mt-0 mb-1 ml-1 mr-1" @input="clearCalendar" v-model="calendarType" :reduce="option => option.value" :clearable="false"
          :options="[
              {label: 'Por Período', value: 'range'},
              {label: 'Por Semana', value: 'week'},
              {label: 'Por Dia', value: 'calendar'}
            ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
          <template #no-options="{}">
            Nenhum resultado encontrado.
          </template>
        </v-select>

        <vc-calendar v-if="calendarType === 'calendar'" class="ml-1 mb-0 flex flex-col"
          :disabled-dates="{ weekdays: [1] }"
          :attributes="attributes"
          @dayclick="onDayClick" />

        <vc-date-picker v-else-if="calendarType === 'range'" class="ml-1 mb-0 flex flex-col" v-model="dateRange" is-range />

        <vc-calendar v-if="calendarType === 'week'" class="ml-1 mb-0 flex flex-col"
          :disabled-dates="{ weekdays: [1] }"
          :attributes="attributes"
          @dayclick="onDayClickWeek" />

        <div class="mt-1 ml-6 mr-6 mb-0 clearfix">
          <vs-button class="w-full" type="line" icon-pack="feather" icon="icon-x" @click="clearCalendar">Limpar Calendário</vs-button>
        </div>

        <vs-divider class="mb-2"></vs-divider>

        <div class="email__lables-list">
          <span class="email__label flex items-center mb-2 justify-center">
            Horário
          </span>
          <span class="email__label flex items-center mb-2 justify-center">
            <vc-date-picker v-model="time" mode="time" class="ml-1 mb-0 flex flex-col" is24hr :timezone="timezone" />
            &nbsp;à&nbsp;
            <vc-date-picker v-model="endTime" mode="time" class="ml-1 mb-0 flex flex-col" is24hr :timezone="timezone" />
          </span>
        </div>

          <!-- <div class="px-0 py-0 pb-0 mt-2 flex flex-col">
            <div class="vx-row">
              <div class="vx-col flex items-center justify-center">
                <vc-date-picker v-model="time" mode="time" class="ml-1 mb-0 flex flex-col" is24hr :timezone="timezone" />
                <vc-date-picker v-model="endTime" mode="time" class="ml-1 mb-0 flex flex-col" is24hr :timezone="timezone" />
              </div>
            </div>
          </div> -->
        <vs-divider></vs-divider>

        <div class="px-4 py-0 pb-0 flex flex-col">
          <div class="vx-row">
            <div class="vx-col flex items-center w-full">
              <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer hover:text-primary">
                  <a href.prevent class="flex cursor-pointer">
                    <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="p-2 rounded-full" :class="[`text-primary`, 'mb-0']"
                      :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
                    <!-- <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="mt-1 ml-3" /> -->
                  </a>
                  <vs-dropdown-menu class="w-60" style="z-index: 200001">
                    <vs-input @change="debouncedFilterDate" type="date" v-model="firstPeriod" class="w-full" label="Data Inicial" />
                    <vs-input @change="debouncedFilterDate" type="date" v-model="lastPeriod" class="w-full" label="Data Final" />
                    <span class="text-danger text-sm" v-show="periodError">O período inicial é maior que o final.</span>
                  </vs-dropdown-menu>
              </vs-dropdown>
              <span class="mb-0 ml-2">Intervalo de Datas</span>
            </div>
          </div>
        </div>
          <vs-divider></vs-divider>
      </component>
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import moment from 'moment'
import _ from 'lodash'

export default {
  data () {
    return {
      timezone: '', //America/Sao_Paulo
      calendarType: 'week',
      dateRange: {},
      firstPeriod: '',
      lastPeriod: '',
      periodError: false,

      activePrompt: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      }
    }
  },
  watch: {
    dateRange (range) {
      const selectedDaysLocal = []
      //to avoid modifying the original date
      const startDate = new Date(`${range.start}-03:00`) // -03:00 LOCALE PTBR
      while (startDate <= new Date(`${range.end}-03:00`)) {
        const [DD, MM, YYYY] = new Date(startDate).toLocaleDateString('pt-br').split('/')
        const today = new Date()
        const sD = new Date(startDate)
        selectedDaysLocal.push({
          studentSchedule: [],
          employeeSchedule: [],
          vehicleSchedule: [],
          holidaySchedule: [],
          id: `${YYYY}-${MM}-${DD}`,
          ariaLabel: null,
          date: sD,
          isToday: sD.getDate() === today.getDate() && sD.getMonth() === today.getMonth() && sD.getFullYear() === today.getFullYear(),
          weekdayPosition: new Date(startDate).getDay() || 7
        })
        startDate.setDate(startDate.getDate() + 1)
      }
      this.$store.commit('schedulePracticalExam/SET_SELECTED_DAYS', selectedDaysLocal)
    }
  },
  computed: {
    period () {   // PERÍODO LONGO, FORA DO CALENDÁRIO
      if (this.firstPeriod && this.lastPeriod) {
        return `${moment(this.firstPeriod).format('DD/MM/YYYY')} até ${moment(this.lastPeriod).format('DD/MM/YYYY')}`
      }
      return `${moment().format('DD/MM/YYYY')} até ${moment().format('DD/MM/YYYY')}`
    },

    dates () {                                          // DATES E ATRIBUTES SERVE PARA MARCAR (highlight)
      return this.selectedDays.map(day => day.date)     // MÚLTIPLOS DIAS NO CALENDÁRIO
    },
    attributes () {
      return this.dates.map(date => ({
        highlight: true,
        dates: date
      }))
    },
    selectedDays: {
      get ()    { return this.$store.state.schedulePracticalExam.selectedDays },
      set (val) { this.$store.commit('schedulePracticalExam/SET_SELECTED_DAYS', val) }
    },
    time: {
      get ()    { return this.$store.state.schedulePracticalExam.time },
      set (val) { this.$store.commit('schedulePracticalExam/SET_TIME', val) }
    },
    endTime: {
      get ()    { return this.$store.state.schedulePracticalExam.endTime },
      set (val) { this.$store.commit('schedulePracticalExam/SET_END_TIME', val) }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    clearCalendar () {
      this.selectedDays = []
      this.dateRange = {}
    },
    onDayClick (day) {
      const selectedDaysLocal = JSON.parse(JSON.stringify(this.selectedDays))
      const idx = selectedDaysLocal.findIndex(d => d.id === day.id)
      if (idx >= 0) {
        selectedDaysLocal.splice(idx, 1)
      } else {
        selectedDaysLocal.push({
          studentSchedule: [],
          employeeSchedule: [],
          vehicleSchedule: [],
          holidaySchedule: [],
          id: day.id,
          ariaLabel: day.ariaLabel,
          date: day.date,
          isToday: day.isToday,
          weekdayPosition: day.weekdayPosition
        })
      }
      this.$store.commit('schedulePracticalExam/SET_SELECTED_DAYS', selectedDaysLocal)
    },
    onDayClickWeek (day) {
      const days = this.weekDays(day.id)
      const selectedDaysLocal = []
      days.forEach(day => {
        const [DD, MM, YYYY] = new Date(day).toLocaleDateString('pt-br').split('/')
        const today = new Date()
        const sD = new Date(day)
        selectedDaysLocal.push({
          studentSchedule: [],
          employeeSchedule: [],
          vehicleSchedule: [],
          holidaySchedule: [],
          id: `${YYYY}-${MM}-${DD}`,
          ariaLabel: null,
          date: sD,
          isToday: sD.getDate() === today.getDate() && sD.getMonth() === today.getMonth() && sD.getFullYear() === today.getFullYear(),
          weekdayPosition: new Date(day).getDay() || 7
        })
      })

      //SÓ ALTERA O STATE CASO A SEMANA MUDE
      if (JSON.stringify(selectedDaysLocal.map(e => e.id)) !== JSON.stringify(this.selectedDays.map(e => e.id))) {
        this.$store.commit('schedulePracticalExam/SET_SELECTED_DAYS', selectedDaysLocal)
      }
    },
    weekDays (date) {
      date = new Date(date)
      const weekDays = new Array()
      // Starting Monday not Sunday
      date.setDate((date.getDate() - date.getDay() + 1))
      for (let i = 0; i < 6; i++) {
        weekDays.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }
      return weekDays
    },
    filterDate () {
      if (moment(this.firstPeriod).year() > 1000 && moment(this.lastPeriod).year() > 1000) {
        const difDays = moment(this.lastPeriod).diff(moment(this.firstPeriod), 'days')
        if (difDays < 0) {
          this.periodError = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período inicial é maior que o final.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        } else if (difDays > (366)) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período não pode ser maior que 1 ano.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        this.$vs.loading()
        this.periodError = false

        setTimeout(function () {
          this.dateRange = { start: new Date(`${this.firstPeriod}T00:00:00.000`), end: new Date(`${this.lastPeriod}T23:59:59.000`) }
          this.$vs.loading.close()
        }.bind(this), 800)

      } else if (!this.firstPeriod && !this.lastPeriod) {
        this.periodError = false
        this.dateRange = {}
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect

  },
  mounted () {
    const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
    this.$nextTick(function () {
      this.onDayClickWeek({ id: `${YYYY}-${MM}-${DD}` })
    }.bind(this))
  },
  created () {
    this.debouncedFilterDate = _.debounce(this.filterDate, 600)
  }
}
</script>

<style lang="scss">
  .vc-date {
    display: none !important;
  }
  .vc-date-time {
    margin-left: 0px !important;
  }
  .vc-time-picker[data-v-ee473b46] {
    display: flex;
    align-items: center;
    padding: 0px !important;
  }
  .vc-time-icon[data-v-ee473b46] {
    display: none !important;
  }
</style>
