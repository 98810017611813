import state from './moduleSchedulePracticalExamState.js'
import mutations from './moduleSchedulePracticalExamMutations.js'
import actions from './moduleSchedulePracticalExamActions.js'
import getters from './moduleSchedulePracticalExamGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

