var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "full-vs-sidebar email-view-sidebar items-no-padding",
          attrs: {
            "click-not-close": "",
            parent: "#schedule-practical",
            "hidden-background": true,
            "position-right": "",
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function ($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mail-sidebar-content px-0 sm:pb-6 h-full" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg",
                },
                [
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer hover:text-primary mr-4",
                          attrs: {
                            icon: _vm.$vs.rtl
                              ? "ArrowRightIcon"
                              : "ArrowLeftIcon",
                            "svg-classes": "w-6 h-6",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeSidebar")
                            },
                          },
                        }),
                        _c("h4", [_vm._v("DETALHAMENTO DE AGENDA")]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "ml-10 mb-4 mt-1" }, [
                    _c("div", {
                      staticClass: "email__actions--single flex items-baseline",
                    }),
                  ]),
                ]
              ),
              _c(
                _vm.scrollbarTag,
                {
                  key: _vm.$vs.rtl,
                  tag: "component",
                  staticClass:
                    "scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6",
                  attrs: { settings: _vm.settings },
                },
                [
                  _vm.isSidebarActive
                    ? _c("div", [
                        _c("div", { staticClass: "vx-row" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("schedule-card", {
                                attrs: { schedule: _vm.scheduleSelected },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "vx-row",
                            staticStyle: { "margin-top": "2.2rem" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full" },
                              [
                                _c(
                                  "vx-card",
                                  [
                                    _c("div", { staticClass: "vx-row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex flex items-center",
                                        },
                                        [
                                          _c("h5", { staticClass: "mb-1" }, [
                                            _vm._v("Grupo"),
                                          ]),
                                          _c(
                                            "vx-tooltip",
                                            {
                                              staticClass: "ml-auto",
                                              attrs: {
                                                text: "Atualizar Notas dos Selecionados",
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "cursor-pointer",
                                                attrs: {
                                                  icon: "CastIcon",
                                                  "svg-classes": "h-6 w-6",
                                                },
                                                on: {
                                                  click: _vm.updateSchedules,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vx-tooltip",
                                            {
                                              staticClass: "ml-4",
                                              attrs: {
                                                text: "Alterar Status",
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "vs-dropdown",
                                                {
                                                  staticClass: "cursor-pointer",
                                                  attrs: {
                                                    "vs-custom-content": "",
                                                    "vs-trigger-click": "",
                                                  },
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      icon: "FolderIcon",
                                                      "svg-classes": "h-6 w-6",
                                                    },
                                                  }),
                                                  _c(
                                                    "vs-dropdown-menu",
                                                    { staticClass: "w-60" },
                                                    [
                                                      _c(
                                                        "vs-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.updateStatus(
                                                                "Apto"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "flex items-center",
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "h-4 w-4 inline-block rounded-full mr-3 bg-success",
                                                              }),
                                                              _c("span", [
                                                                _vm._v("Apto"),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.updateStatus(
                                                                "Inapto"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "flex items-center",
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "h-4 w-4 inline-block rounded-full mr-3 bg-danger",
                                                              }),
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Inapto"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.updateStatus(
                                                                "Aguardando Teste"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "flex items-center",
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary",
                                                              }),
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Aguardando Teste"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vx-tooltip",
                                            {
                                              staticClass: "ml-4",
                                              attrs: {
                                                text: "Deletar Selecionados",
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "cursor-pointer",
                                                attrs: {
                                                  icon: "TrashIcon",
                                                  "svg-classes": "h-6 w-6",
                                                },
                                                on: {
                                                  click: _vm.confirmDelete,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "vs-table",
                                      {
                                        staticClass: "bordered",
                                        staticStyle: {
                                          overflow: "-webkit-paged-y",
                                        },
                                        attrs: {
                                          noDataText: "Nenhum Agendamento.",
                                          data: _vm.schedules,
                                          hoverFlat: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ data }) {
                                                return _vm._l(
                                                  data,
                                                  function (schedule, indextr) {
                                                    return _c(
                                                      "vs-tr",
                                                      {
                                                        key: indextr,
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                          opacity:
                                                            "1 !important",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "vs-td",
                                                          [
                                                            _c("vs-checkbox", {
                                                              staticClass:
                                                                "vs-checkbox-small ml-0 mr-1",
                                                              attrs: {
                                                                "vs-value":
                                                                  schedule,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.selectedSchedules,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedSchedules =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "selectedSchedules",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c("vs-td", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cursor-default font-semibold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  schedule
                                                                    .student
                                                                    .name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _c("vs-td", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cursor-default font-semibold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  schedule
                                                                    .student.cpf
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _c("vs-td", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cursor-default font-semibold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  schedule.category
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _c("vs-td", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cursor-default font-semibold flex items-center",
                                                              class: `text-${
                                                                schedule.status ===
                                                                "Apto"
                                                                  ? "success"
                                                                  : schedule.status ===
                                                                    "Inapto"
                                                                  ? "danger"
                                                                  : schedule.status ===
                                                                    "Apto Com Restrições"
                                                                  ? "warning"
                                                                  : schedule.status ===
                                                                    "ABSENCE"
                                                                  ? "secondary"
                                                                  : ""
                                                              }`,
                                                            },
                                                            [
                                                              schedule.status ===
                                                              "ABSENCE"
                                                                ? _c(
                                                                    "feather-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      attrs: {
                                                                        icon: "SlashIcon",
                                                                        svgClasses:
                                                                          "w-4 h-4",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "scheduleStatus"
                                                                    )(
                                                                      schedule.status
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2195715083
                                        ),
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "thead" },
                                          [
                                            _c(
                                              "vs-th",
                                              {
                                                staticStyle: {
                                                  "font-size": "11px",
                                                  width: "40px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "vs-checkbox",
                                                  {
                                                    staticClass:
                                                      "select-all-chexkbox",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.schedules.length,
                                                      "icon-pack": "feather",
                                                      icon: _vm.selectAllIcon,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectAllCheckBox,
                                                      callback: function ($$v) {
                                                        _vm.selectAllCheckBox =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectAllCheckBox",
                                                    },
                                                  },
                                                  [_vm._v("Todos")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "vs-th",
                                              {
                                                staticStyle: {
                                                  "font-size": "11px",
                                                },
                                              },
                                              [_c("span", [_vm._v("ALUNO")])]
                                            ),
                                            _c(
                                              "vs-th",
                                              {
                                                staticStyle: {
                                                  "font-size": "11px",
                                                },
                                              },
                                              [_c("span", [_vm._v("CPF")])]
                                            ),
                                            _c(
                                              "vs-th",
                                              {
                                                staticStyle: {
                                                  "font-size": "11px",
                                                },
                                              },
                                              [_c("span", [_vm._v("CATEG.")])]
                                            ),
                                            _c(
                                              "vs-th",
                                              {
                                                staticStyle: {
                                                  "font-size": "11px",
                                                },
                                              },
                                              [_c("span", [_vm._v("STATUS")])]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }