import { render, staticRenderFns } from "./PracticalExamSidebar.vue?vue&type=template&id=70dcf3f8&"
import script from "./PracticalExamSidebar.vue?vue&type=script&lang=js&"
export * from "./PracticalExamSidebar.vue?vue&type=script&lang=js&"
import style0 from "./PracticalExamSidebar.vue?vue&type=style&index=0&id=70dcf3f8&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70dcf3f8')) {
      api.createRecord('70dcf3f8', component.options)
    } else {
      api.reload('70dcf3f8', component.options)
    }
    module.hot.accept("./PracticalExamSidebar.vue?vue&type=template&id=70dcf3f8&", function () {
      api.rerender('70dcf3f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/schedule/PracticalExam/PracticalExamSidebar.vue"
export default component.exports