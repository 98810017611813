var render = function render(_c, _vm) {
  return _c("vx-card", { staticClass: "px-4", class: _vm.data.staticClass }, [
    _c("div", { staticClass: "vx-row" }, [
      _vm.schedule && _vm.schedule.id
        ? _c(
            "div",
            {
              staticClass:
                "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center",
            },
            [
              _c("div", { staticClass: "flex items-center" }, [
                _c("div", { staticClass: "flex flex-col" }, [
                  _c("h5", { staticClass: "mb-1" }, [
                    _vm._v("Examinador: " + _vm._s(_vm.props.schedule.extra)),
                  ]),
                  _c("h5", { staticClass: "mb-1" }, [
                    _vm._v(
                      "Instrutor: " +
                        _vm._s(
                          _vm.props.schedule.employee_id
                            ? _vm.props.schedule.employee.name
                            : ""
                        )
                    ),
                  ]),
                  _c("h5", { staticClass: "mb-1" }, [
                    _vm._v(
                      "Veículo: " +
                        _vm._s(
                          _vm.props.schedule.vehicle
                            ? _vm.props.schedule.vehicle.brand +
                                " " +
                                _vm.props.schedule.vehicle.model +
                                " - " +
                                _vm.props.schedule.vehicle.license_plate
                            : ""
                        )
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "flex flex-col justify-end" }, [
                _c("span", { staticClass: "text-right font-semibold" }, [
                  _vm._v(
                    "Data: " +
                      _vm._s(
                        _vm._f("dateTime")(
                          _vm.props.schedule.start_date,
                          "date"
                        )
                      )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "flex justify-end mt-2 font-semibold" },
                  [
                    _c("span", { staticClass: "text-right" }, [
                      _vm._v(
                        "Horário: " +
                          _vm._s(_vm.props.schedule.start_time.slice(0, 5)) +
                          " até " +
                          _vm._s(_vm.props.schedule.end_time.slice(0, 5))
                      ),
                    ]),
                  ]
                ),
                _c("div", {
                  staticClass: "flex justify-end mt-2 font-semibold",
                }),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }