const now = new Date()
export default {
  schedules: [],        // AGENDAS MOSTRADAS NO DETALHAMENTO DE GRUPO DE AGENDAMENTO
  selectedDays: [],
  studentSchedules: [],
  holidaySchedules: [],
  time: new Date(),
  endTime: now.setHours(now.getHours() + 1)
  // ATENÇÃO, AO UTILIZAR O MÓDULO SCHEDULETIME NÃO ESQUECER DE INSERIR O STATUS NO IF DO ACTION DELETE DESTE MÓDULO
  // PARA QUE A AGENDA CORRETA SEJA EXCLUÍDA DO STATE
}
